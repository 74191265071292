import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import ExportSymposium from "./components/sympsium/ExportSymposium";
import SecurityScan from "./components/sympsium/SecurityScan";
import ScanBadge from "./components/sympsium/ScanBadge";
import SearchVisitorsSymposium from "./components/sympsium/SearchVisitorsSymposium";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={1500} />
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/export-symposium-users" element={<ExportSymposium />} />
        <Route path="/symposium-security-scan" element={<SecurityScan />} />
        <Route path="/symposium-badge-scanner" element={<ScanBadge />} />
        <Route path="/symposium-search" element={<SearchVisitorsSymposium />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
