import React from "react";
import { Button, Stack } from "@mui/material";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExportSymposium = () => {
  const handleExport = async () => {
    try {
      // Fetch data from the backend API
      const response = await axios.get(
        "https://apis.expoplanner.in/event/get-symposium-users"
      );
      const data = response.data;

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string and save it
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "data.xlsx");
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    }
  };
  const handleLatestExport = async () => {
    try {
      // Fetch data from the backend API
      const response = await axios.get(
        "https://apis.expoplanner.in/event/get-symposium-scanned-users-all"
      );
      const data = response.data;

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string and save it
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "data.xlsx");
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    }
  };

  return (
    <Stack direction={"row"} gap={2}>
      <Button variant="contained" color="primary" onClick={handleExport}>
        Export Data to Excel
      </Button>
      <Button variant="contained" color="primary" onClick={handleLatestExport}>
        Export Scanned Data to Excel
      </Button>
    </Stack>
  );
};

export default ExportSymposium;
