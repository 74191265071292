import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
} from "@mui/material";
import axios from "axios";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { ImtexBadgeDimenstion } from "../../badge/ImtexBadgeDimension";
import ReactDOMServer from "react-dom/server";
const SearchVisitorsSymposium = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSearch = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await axios.get(
        "https://apis.expoplanner.in/event/search-visitors-symposium",
        {
          params: { sq: searchQuery },
        }
      );
      setVisitors(response.data);
    } catch (err) {
      setError("No results found or something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onPrint = (item) => {
    printRow(item);
  };

  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <ImtexBadgeDimenstion row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Search Visitors (Name or Mobile)
      </Typography>
      <TextField
        label="Search by Name or Mobile"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        disabled={loading}
      >
        {loading ? "Searching..." : "Search"}
      </Button>

      {error && <Typography color="error">{error}</Typography>}

      {visitors.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors.map((visitor) => (
                <TableRow key={visitor._id}>
                  <TableCell>{visitor.title}</TableCell>
                  <TableCell>{visitor.first_name}</TableCell>
                  <TableCell>{visitor.last_name}</TableCell>
                  <TableCell>{visitor.email}</TableCell>
                  <TableCell>{visitor.mobile}</TableCell>
                  <TableCell>{visitor.company}</TableCell>
                  <TableCell>{visitor.designation}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => onPrint(visitor)} size="small">
                      <LocalPrintshopIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default SearchVisitorsSymposium;
