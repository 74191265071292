import React, { useState } from "react";
import QRCode from "react-qr-code";
export class ImtexBadgeDimenstion extends React.Component {
  render() {
    const { row } = this.props;
    const vCard = row.email;
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
          >
            {row.first_name?.toUpperCase()} {row.last_name?.toUpperCase()}{" "}
            {row.name?.toUpperCase()}
          </div>
          <div style={{ fontSize: "19px", marginTop: "12px" }}>
            {row.company?.toUpperCase()}
            {row.instituteName?.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
